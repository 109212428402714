import React from 'react';
import { TextField, Typography, Stack, TextFieldProps } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { useFormContext, useController } from 'react-hook-form';
import { BaseFormComponentProps } from '../../types';
import moment from 'moment';
export type DatePickerProps = BaseFormComponentProps & TextFieldProps & {
    datePickerProps: Omit<MuiDatePickerProps, "onChange" | "renderInput">
}

const FormDatePicker = ({ name, label, defaultValue, datePickerProps, ...other }: DatePickerProps) => {
    const { control, formState, trigger } = useFormContext();
    const { field, fieldState } = useController({ name, control, defaultValue });

    const handleChange = React.useCallback(
        (date: any) => {
            field.onChange({ target: { value: date ? date : null } });
        },
        [field]
    );

    return (
        <Stack>
            <Typography>{label}</Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    value={field.value} 
                    onChange={(newDate: string | null) => {
                        handleChange(moment(newDate).format("YYYY-MM-DD"))
                    }}
                    inputFormat="dd/MM/yyyy"
                    mask="__/__/____"
                    showTodayButton
                    disabled={other?.disabled}
                    renderInput={(params: any) => (
                        <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            onBlur={async () => {
                                if (formState.isSubmitted) {
                                    await trigger([name]);
                                }
                            }}
                            {...params}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            inputProps={{ ...params.inputProps, sx: { pl: 2 } }}
                            {...other}
                        />
                    )}
                    // {...datePickerProps}
                />
            </LocalizationProvider>
        </Stack>
    );
};

export default FormDatePicker;
