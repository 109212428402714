import React from 'react';

/* third-party imports */
import { DataMap } from '@grapecity/wijmo.grid';
import {
  FlexGrid,
  FlexGridColumn,
  FlexGridCellTemplate,
} from '@grapecity/wijmo.react.grid';
import '@grapecity/wijmo.styles/wijmo.css';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';

/* project imports */
import { GridColumn } from '../../types';
import useQGridLogic from './useQGridLogic';
import { QGridProps } from './types';
import { TablePagination } from '@mui/material';

/**
 * 
 * @param {QGridProps} props GridProps configuration
 * @param ref Ref that provides the wijmo FlexGrid class object
 * @returns JSX component that renders a wijmo FlexGrid with specified columns & rows
 * 
 * @example 
 *  const App = () => {
    const ref = React.useRef(null);
    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);

    React.useEffect(() => {
      const timer = setTimeout(() => {
        setColumns(testHeader);
        setRows(testData);
      }, 2000);

      return () => clearTimeout(timer);
    }, []);

    return (
      <QGrid
        ref={ref}
        columns={columns}
        rows={rows}
        bringValue={{
          getData: handleBringValue,
          getParams: getParams,
        }}
        onRowsChanged={(rows) => setRows(rows)}
      />
    );
  };
 */
const QGrid = React.forwardRef(
  (props: QGridProps, ref: React.ForwardedRef<any>) => {
    const {
      columns,
      gridInitialized,
      rows,
      flexGridProps,
      paginationOptions: { onPaginationChanged, ...paginationOptions },
      paginationState,
    } = useQGridLogic(props, ref);

    const { t, i18n } = useTranslation(props.translationNs ?? 'grids');

    return (
      <div className="Qub-DatagridContainer">
        <div className="Qub-DatagridFlexContainer">
          <FlexGrid
            autoSizeMode={3}
            initialized={gridInitialized}
            itemsSource={rows}
            autoGenerateColumns={isNil(columns)}
            {...flexGridProps}
          >
            {props.children}
            {columns?.length > 0 &&
              columns.map((c: GridColumn, index: number) => {
                if (
                  isNil(c.header) &&
                  c.visible !== false &&
                  !isNil(i18n.language)
                )
                  c.header = t(c.binding);

                let dataMap = undefined;
                if (!isNil(c.options) && Array.isArray(c.options))
                  dataMap = new DataMap(c.options, 'id', 'value');

                return (
                  <FlexGridColumn
                    key={index}
                    {...c}
                    header={c.isBringValue ? `${c.header}  🔍︎` : c.header}
                    dataMap={dataMap}
                  >
                    {c.cellTemplates?.map((ct, i) => (
                      <FlexGridCellTemplate
                        key={i}
                        cellType={ct.cellType}
                        template={(ctx: any) => ct.template(ctx, c)}
                      />
                    ))}
                  </FlexGridColumn>
                );
              })}
          </FlexGrid>
        </div>
        {!isNil(props.paginationOptions) && (
          <div className="Qub-DatagridPaginationContainer">
            {rows.length > 0 && (
              <TablePagination
                component="div"
                {...paginationOptions}
                {...paginationState}
              />
            )}
          </div>
        )}
      </div>
    );
  }
);

export default QGrid;
