import React, { MutableRefObject, useState } from 'react';
import {
  QBringValueHandler,
  QBringValueHandlerOptions,
  QBringValueRef,
  QBringValueState,
} from './types';
import { handleSelectBringValueDefault } from '../QGrid/utils';
import { QGridRef } from '../QGrid/types';
import { isNil } from 'lodash';

const handleDblClick = (
  flex: any,
  e: any,
  bringValueRef: MutableRefObject<QBringValueRef | null>,
  popupRef: MutableRefObject<any>
) => {
  let ht = flex.hitTest(e.pageX, e.pageY);
  const view = ht?.grid?.collectionView;
  const currentItem = view.currentItem;
  if (currentItem && ht.cellType === 1) {
    bringValueRef.current!.handleSelected({
      item: currentItem,
      bringValueRef: bringValueRef.current!,
    });
    popupRef.current.hide();
  }
};

const handleOnEnter = (
  flex: any,
  e: any,
  bringValueRef: MutableRefObject<QBringValueRef | null>,
  popupRef: MutableRefObject<any>
) => {
  const view = flex.collectionView;

  if ((e.code === 'Enter' || e.code === 'NumpadEnter') && view.currentItem) {
    e.preventDefault();
    e.stopPropagation();

    /* Runs handle select */
    bringValueRef.current!.handleSelected({
      item: view.currentItem,
      bringValueRef: bringValueRef.current!,
    });
    popupRef.current.hide();
  }
};

const useQBringValueLogic = () => {
  const [bringValueState, setBringValueState] =
    useState<QBringValueState | null>(null);
  const bringValueRef = React.useRef<QBringValueRef | null>(null);
  const popupRef = React.useRef<any>();
  const bvGridRef = React.useRef<QGridRef>();

  React.useEffect(() => {
    if (!isNil(bringValueState) && bringValueState.rows?.length > 1) {
      popupRef.current.show(true);
      bvGridRef.current?.focus();
    }
  }, [bringValueState]);

  /**
   * Popup initialized
   * @param popup Wijmo popup object
   */
  const popupInitialized = (popup: any) => {
    popup.isDraggable = true;
    popup.isResizable = true;
    popup.fadeOut = false;
    popup.fadeIn = false;
    // popup.onHidden = () => {
    //   raiseBringValueClose();
    // };

    /* (Hot-)Fixing bug that hides the popup when pagination options are clicked */
    popup.hideTrigger = 'None';

    popupRef.current = popup;
  };

  const bringValue = async (
    handler: QBringValueHandler,
    options: QBringValueHandlerOptions
  ) => {
    if (isNil(options.onStatusChanged))
      options.onStatusChanged = (_: any) => {};

    try {
      options.onStatusChanged('loading');
      const result = await handler();

      if (isNil(result.rows))
        throw new Error(
          'DatagridError::Return rows as array from bring value handler'
        );

      // Update ref on bv call
      bringValueRef.current = {
        popupRef: popupRef.current,
        bvGridRef: bvGridRef.current,
        hostGridRef: options.hostGridRef?.current,
        state: result,
        handleSelected: options.onSelected ?? handleSelectBringValueDefault,
      };

      switch (result.rows.length) {
        case 0:
          console.warn('Datagrid::No rows returned from bringValue');
          if (!isNil(options.onNotFound))
            options.onNotFound({
              item: {},
              bringValueRef: bringValueRef.current,
            });
          break;
        case 1:
          console.log('Datagrid::Found one: ', result.rows[0]);
          bringValueRef.current.handleSelected({
            item: result.rows[0],
            bringValueRef: bringValueRef.current,
          });
          break;
        default:
          console.log('Datagrid::Multiple items returned', result.rows);
          // Trigger UI change
          setBringValueState(result);
          break;
      }
      options.onStatusChanged('success');
    } catch (ex) {
      options.onStatusChanged('error');
      console.error('Datagrid::An error occured during bring value', ex);
    }
  };

  const additionalInitialization = (flex: any) => {
    flex.stickyHeaders = true;
    flex.headersVisibility = 'Column';
    flex.selectionMode = 'Row';
    flex.keyActionEnter = 'None';
    flex.isReadOnly = true;

    flex.hostElement.addEventListener('dblclick', (e: any) =>
      handleDblClick(flex, e, bringValueRef, popupRef)
    );

    flex.hostElement.addEventListener('keydown', (e: any) =>
      handleOnEnter(flex, e, bringValueRef, popupRef)
    );
  };

  return {
    bringValueState,
    popupInitialized,
    bringValue,
    additionalInitialization,
    bringValueRef,
    bvGridRef,
  };
};

export default useQBringValueLogic;
