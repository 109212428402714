import React from 'react';
// mui
import { TextField, Typography, Stack, TextFieldProps } from '@mui/material';

// hook forms
import { useFormContext } from 'react-hook-form';

import { useEffect } from 'react';
import { BaseFormComponentProps } from '../../types';

export type TextInputProps = BaseFormComponentProps & Omit<TextFieldProps, keyof BaseFormComponentProps>

const TextInput = (props: TextInputProps) => {
    const { register, formState, setValue } = useFormContext();
    const { errors } = formState;
    const { name, rules, label, defaultValue, ...other } = props;

    useEffect(() => {
        setValue(name, defaultValue);
    }, [setValue, name, defaultValue]);

    return (
        <Stack>
            <Typography>{label}</Typography>
            <TextField
                sx={{
                    borderColor: 'red'
                }}
                inputProps={{
                    ...register(name, rules)
                }}
                InputLabelProps={{ shrink: true }}
                error={!!errors[name]}
                helperText={errors[name]?.message}
                required={rules?.required}
                size="small"
                fullWidth
                {...other}
            />
        </Stack>
    );
};

export default TextInput;
