import React from "react";
import { Typography } from "@mui/material";

export type withSkeletonProps = {
    status: 'loading' | 'success' | 'error'
}

export const withSkeleton: React.ReactNode = (Component: React.ComponentType, Skeleton: React.ComponentType) => (props: withSkeletonProps & any ) => {
    const { status } = props;
    if (status === 'success') {
        return <Component {...props} />;
    }
    if (status === 'loading') {
        return <Skeleton />;
    }
    if (status === 'error') {
        return <Typography variant="subtitle1">There was an error fetching your data :(</Typography>;
    }
    return null;
};