import React, { useContext } from 'react';
import { Popup } from '@grapecity/wijmo.react.input';

import { UseQBringValueProps } from './types';
import { notImplemented } from '../QGrid/utils';
import QGrid from '../QGrid';
import useQBringValueLogic from './useQBringValueLogic';

const Context = React.createContext<UseQBringValueProps>({
  bringValue: notImplemented(
    "DatagridError::Too soon to use bringValue (shouldn't be thrown ever)"
  ),
});

function useQBringValue(): UseQBringValueProps {
  const qBringValueContext = useContext(Context);
  if (qBringValueContext === undefined) {
    throw new Error(
      'useQBringValue should be used within a QBringValueProvider'
    );
  }
  return qBringValueContext;
}

const QBringValueProvider = (props: { children: React.ReactNode }) => {
  const {
    bringValueState,
    bringValue,
    popupInitialized,
    bringValueRef,
    bvGridRef,
    additionalInitialization,
  } = useQBringValueLogic();

  return (
    <Context.Provider value={{ bringValue, ref: bringValueRef }}>
      <Popup
        initialized={popupInitialized}
        style={{ width: '75vw', height: '90vh' }}
      >
        <div
          className="wj-dialog-header Qub-DatagridBringValueView"
          style={{ backgroundColor: 'lightgray' }}
        >
          {`Searching '${
            (Array.isArray(bringValueState?.filters) &&
              bringValueState?.filters[0]?.filter) ||
            ''
          }'`}
          <button type="button" tabIndex={-1} className="close wj-hide">
            &times;
          </button>
        </div>
        <QGrid
          ref={bvGridRef}
          rows={bringValueState?.rows ?? []}
          columns={bringValueState?.columns}
          initializeGrid={additionalInitialization}
          style={{
            height: 'calc(90vh - 115px)',
          }}
        />
        <div
          className="modal-footer"
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: 24,
            height: 35,
          }}
        >
          {bringValueState?.rows?.length ?? 0} of{' '}
          {bringValueState?.rowCount ?? 0} records
        </div>
      </Popup>
      {props.children}
    </Context.Provider>
  );
};

const QBringValueConsumer = Context.Consumer;

export { useQBringValue, QBringValueProvider, QBringValueConsumer };
