import React from 'react';
import { InputAdornment, TextField, TextFieldProps, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { BaseFormComponentProps } from '../../types';

export type CurrencyInputProps = BaseFormComponentProps & TextFieldProps;

const CurrencyInput = (props: CurrencyInputProps) => {
    const { register, formState } = useFormContext();
    const { errors } = formState;
    const { name, rules, label, ...other } = props;
    return (
        <>
            <Typography>{label}</Typography>
            <TextField
                InputProps={{
                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                    inputProps: {
                        ...register(name, rules),
                        style: {
                            textAlign: 'right'
                        }
                    }
                }}
                error={!!errors[name]}
                helperText={errors[name]?.message}
                required={rules?.required}
                size="small"
                fullWidth
                {...other}
            />
        </>
    );
};

export default CurrencyInput;
