import React from 'react';
import { TextField, Typography, Stack, TextFieldProps } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker, DateTimePickerProps as MuiDateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker';
import { useFormContext, useController } from 'react-hook-form';
import { BaseFormComponentProps } from 'types';
import moment from 'moment';

export type DateTimePickerProps = BaseFormComponentProps & TextFieldProps & {
    datePickerProps: Omit<MuiDateTimePickerProps, "onChange" | "renderInput">
}

const FormDateTimePicker = ({ name, label, defaultValue, datePickerProps, ...other }: DateTimePickerProps) => {
    const { control, formState, trigger } = useFormContext();
    const { field, fieldState } = useController({ name, control, defaultValue: moment(defaultValue).format("YYYY-MM-DD HH:mm") });
    const handleChange = React.useCallback(
        (date: any) => {
            field.onChange({ target: { value: date ? date : null } });
        },
        [field]
    );

    return (
        <Stack>
            <Typography>{label}</Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                    ampm={false}
                    value={field.value} 
                    onChange={(newDate: string | null) => {
                        handleChange(moment(newDate).format("YYYY-MM-DD HH:mm"))
                    }}
                    inputFormat="dd/MM/yyyy HH:mm"
                    showTodayButton
                    disabled={other?.disabled}
                    renderInput={(params: any) => (
                        <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            onBlur={async () => {
                                if (formState.isSubmitted) {
                                    await trigger([name]);
                                }
                            }}
                            {...params}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            inputProps={{ ...params.inputProps, sx: { pl: 2 } }}
                            {...other}
                        />
                    )}
                    // {...datePickerProps}
                />
            </LocalizationProvider>
        </Stack>
    );
};

export default FormDateTimePicker;
