import React from 'react';
import { Grid } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { Dropdown, TextInput, DatePicker, Checkbox, DateTimePicker} from '../InputFields';
import { BaseFormComponentProps } from '../../types';
// import { boolean } from 'yup';

export type DynamicFieldProps = BaseFormComponentProps & {
  id: any,
  value: any,
  type: 'string' | 'boolean' | 'date' | 'dropdown',
  isReadOnly?: boolean
} & any;

const DynamicField = (props: DynamicFieldProps) => {
  const { name, type, isReadOnly, value, label, id, ...rest } = props;
  let correctValue = value;
  switch (type) {
    case 'string':
      return (
        <TextInput
          id={`${name}-${id}`}
          name={name}
          label={label}
          disabled={isReadOnly}
          defaultValue={value}
          variant='outlined'
          {...rest}
        />
      );
    case 'date':
      return (
        <DatePicker
          id={`${name}-${id}`}
          name={name}
          label={label}
          disabled={isReadOnly}
          defaultValue={value}
          {...rest}
        />
      );
    case 'datetime':
      return (
        <DateTimePicker
          id={`${name}-${id}`}
          name={name}
          label={label}
          disabled={isReadOnly}
          defaultValue={value}
          {...rest}
        />
      )
    case 'boolean':
      if (typeof value === 'string') correctValue = value === '1' || value === 'true';
      return (
        <Checkbox
          id={`${name}-${id}`}
          name={name}
          label={label}
          disabled={isReadOnly}
          defaultValue={correctValue}
          {...rest}
        />
      );
    case 'dropdown':
      return (
        <Dropdown
          id={`${name}-${id}`}
          name={name}
          label={label}
          disabled={isReadOnly}
          defaultValue={value}
          {...rest}
        />
      );
    default:
      return null;
  }
};

export type DynamicFormProps = {
  form: any,
  methods: any,
  allowed?: boolean
}

const DynamicForm = ({ form, methods, allowed }: DynamicFormProps) => (
  <FormProvider {...methods}>
    <Grid container spacing={2}>
      {form.map((field: any) => {
        if (allowed !== undefined) {
          field.isReadOnly = !allowed || (allowed && field.isReadOnly)
        }
        return (
          <Grid key={field.id} item xs={12} md={6}>
            <DynamicField {...field} />
          </Grid>
        )
      })}
    </Grid>
  </FormProvider>
);

export default DynamicForm;
