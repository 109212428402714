import { isNil } from 'lodash';
import { GridColumn, GridWjColumn } from '../../../types';
import {
  QBringValueHandler,
  QBringValueHandlerOptions,
  QHandleSelectedBringValue,
} from '../../QBringValue/types';
import { QGridRef } from '../types';
import { BringValueCallbacks } from '../types';

export const bringValueHandler = async (props: {
  flex: any;
  e: any;
  columns: GridColumn[];
  handleBringValue:
    | ((
        handler: QBringValueHandler,
        options: QBringValueHandlerOptions
      ) => void)
    | undefined;
  ref: QGridRef;
  bringValue?: BringValueCallbacks;
}) => {
  const { flex, e, columns, handleBringValue, bringValue, ref } = props;
  /* Get extended column object */
  const currentCol = columns!.find(
    (c) => c.binding === flex.columns[e.col].binding
  )!;

  /* In case column is bring value */
  if (currentCol.isBringValue && flex.activeEditor) {
    e.cancel = true;
    const value = flex.activeEditor.value;

    /* Construct filter */
    let filters: any;
    if (currentCol.flatFilters) {
      filters = {
        [currentCol.bringValueField!]: `${value || ''}*`,
      };
    } else {
      filters = [
        {
          searchField: currentCol.bringValueField!,
          filter: `${value || ''}*`,
          operation: 'custom',
        },
      ];
    }

    /* Call bring value api */
    if (!isNil(handleBringValue))
      await handleBringValue(
        async () => {
          const result = await bringValue?.getData({
            dataKey: currentCol.bringValueTable!,
            filters,
          });

          /* assert getData result */
          if (isNil(result.rows) || isNil(result.rowCount))
            throw new Error(
              'DatagridError::Bring value getData should return { rows, rowCount, columns? }'
            );

          return {
            dataKey: currentCol.bringValueTable,
            filters,
            coordinates: {
              col: e.col,
              row: e.row,
            },
            ...result,
          };
        },
        {
          hostGridRef: ref,
          onNotFound: () => clearDependents({ flex, e, column: currentCol }),
        }
      );
  }
};

/**
 * Default function to execute after a bring value selection has been made. Overrideable.
 *
 * @param {QHandleSelectedBringValue} props
 */
export const handleSelectBringValueDefault = (
  props: QHandleSelectedBringValue
) => {
  const {
    item,
    bringValueRef, // columns, bringValueProps, dataKey, onSelectedItem
  } = props;

  const grid = bringValueRef.hostGridRef;

  const columns = bringValueRef.hostGridRef.customColumns;

  const bringValueCoordinates = bringValueRef.state.coordinates;

  const { collectionView } = grid;

  /* Get coordinates */
  const { row, col } = bringValueCoordinates!;

  /* Find item */
  const currentItem = grid.rows[row!].dataItem;

  /* Get flex column object */
  const currentCol = grid.columns[col!];

  /* Start edit transaction */
  collectionView.editItem(currentItem);

  /* Get extended column */
  const extendedCol = columns.find(
    (c: GridColumn) => c.binding === currentCol.binding
  )!;

  /* Change same */
  grid.setCellData(
    row,
    extendedCol.binding,
    item[extendedCol.bringValueField ?? '']
  );

  fillDependents({ flex: grid, e: { row, col }, column: extendedCol, item });

  // if(!isNil(onSelectedItem)) onSelectedItem({item, grid, dataKey, currentItem});

  /* Commit edits */
  collectionView.commitEdit();

  grid.focus();
};

/**
 * This function can be used to clear bring value dependents according to GridColumn [dependents] specification
 * @param flex Wijmo flex object
 * @param e Wijmo event object,
 * @param column GridColumn object extended by Qubiteq
 */
export const clearDependents = (props: {
  flex: any;
  e: any;
  column: GridColumn;
}) => {
  const { flex, e, column } = props;
  /* Clear dependents if no result */
  flex.setCellData(e.row, e.col, null);
  column?.dependents
    ?.split(',')
    .map((d) => {
      const [binding] = d.split(':'); // Get first item of string type [binding]:[dependsOn]
      return flex.columns.find((c: GridWjColumn) => c.binding === binding);
    })
    .forEach((col) => {
      if (isNil(col))
        throw new Error(
          `Datagrid::error Dependents of column ${column.header} are incorrect ( AMT judging you >:| )`
        );
      flex.setCellData(e.row, col.index, null);
    });
  flex.finishEditing(true);
};

/**
 * This function can be used to fill bring value dependents according to GridColumn [dependents] specification and the selected item
 * @param flex Wijmo flex object
 * @param e Wijmo event object,
 * @param column GridColumn object extended by Qubiteq
 * @param item Item selected from bring value
 */
export const fillDependents = (props: {
  flex: any;
  e: any;
  column: GridColumn;
  item: any;
}) => {
  const { column, flex, item, e } = props;
  /* Change dependents */
  column?.dependents
    ?.split(',')
    .map((d: string) => d.split(':'))
    .forEach(
      ([dependent, dependsOn]: string[]) =>
        dependent &&
        flex.setCellData(e.row, dependent, item[dependsOn ?? dependent])
    );
};
