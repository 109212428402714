import React from 'react';

import { QGridProps } from './types';
import { isNil } from 'lodash';
import { useQBringValue } from '../QBringValue';
import { autoSize, clearDependents, bringValueHandler } from './utils';
import { GridColumn } from '../../types';

const useQGridLogic = (
  props: QGridProps,
  ref: React.ForwardedRef<any>
): any => {
  const {
    rows: providedRows,
    columns,
    onRowsChanged,
    onColumnsChanged,
    bringValue,
    initializeGrid,
    paginationOptions,
    sortOnServer = false,
    onRowClicked,
    ...other
  } = props;

  const gridRef: React.MutableRefObject<any> = React.useRef();
  const [status, setStatus] = React.useState('initializing');
  const [rows, setRows] = React.useState<any[]>([]); // Using this state in case the provided rows are not a state
  const paginationState = paginationOptions?.state;

  if (isNil(columns) && !isNil(bringValue))
    console.warn(
      'DatagridError::Bring value will only work by specifying the columns array object.'
    );

  /* Call bring value hook */
  const { bringValue: handleBringValue } = useQBringValue();

  /* If provided rows are state then listen to their changes and fetch them to our internal state */
  React.useEffect(() => {
    setRows(providedRows ?? []);
  }, [providedRows]);

  // ON COLUMNS CHANGED set up the listeners for bring value again
  React.useEffect(() => {
    onColumnsChanged && !isNil(columns) && onColumnsChanged(columns);

    const bv = async (flex: any, e: any) =>
      bringValueHandler({
        flex,
        e,
        columns: columns!,
        handleBringValue,
        ref,
        bringValue,
      });

    if (!isNil(columns)) {
      autoSize({ flex: gridRef.current, columns });
      // if columns are auto generated do not add bring value handler
      gridRef.current?.cellEditEnding.removeHandler(bv);
      gridRef.current?.cellEditEnding.addHandler(bv);
      gridRef.current?.cellEditEnded.addHandler((flex: any, e: any) => {
        if (e.data instanceof KeyboardEvent && e.data.code === 'Backspace') {
          clearDependents({
            flex,
            e,
            column: columns.find(
              (c: GridColumn) => flex.getColumn(e.col).binding === c.binding
            )!,
          });
        }
      });
    }

    return () => {
      gridRef.current?.cellEditEnding.removeHandler(bv);
    };
  }, [columns]);

  // This is to avoid onColumnsChanged to run when onRowsChanged function bad implementation
  React.useEffect(() => {
    const updateChanges = (flex: any) => {
      onRowsChanged && onRowsChanged([...flex.itemsSource]);
    };

    gridRef.current?.cellEditEnded.removeHandler(updateChanges);
    gridRef.current?.cellEditEnded.addHandler(updateChanges);

    return () => {
      gridRef.current?.cellEditEnded.removeHandler(updateChanges);
    };
  }, [columns, onRowsChanged]);

  // ON ROWS CHANGED
  React.useEffect(() => {
    autoSize({
      flex: gridRef.current,
      columns: columns ?? gridRef.current.columns,
    });

    onRowsChanged && onRowsChanged(rows);
  }, [rows]);

  React.useEffect(() => {
    const resetPagination = () => {
      onPageChange(null, 0);
    };

    if (sortOnServer) {
      gridRef.current?.sortedColumn.removeHandler(resetPagination);
      gridRef.current?.sortedColumn.addHandler(resetPagination);
    }

    return () => {
      if (sortOnServer)
        gridRef.current?.sortedColumn.removeHandler(resetPagination);
    };
  }, [paginationOptions]);

  // This is to update listener with new onRowClicked function
  React.useEffect(() => {
    const onRowClickedFn = (e: any) => {
      if (onRowClicked) {
        const ht = gridRef.current.hitTest(e.pageX, e.pageY);
        const currentItem = ht?.grid?.collectionView.currentItem;
        if (currentItem && ht.cellType === 1) {
          onRowClicked(currentItem, ht);
        }
      }
    };

    gridRef.current?.hostElement.removeEventListener(
      'mousedown',
      onRowClickedFn
    );
    gridRef.current?.hostElement.addEventListener('mousedown', onRowClickedFn);

    return () => {
      gridRef.current?.hostElement.removeEventListener(
        'mousedown',
        onRowClickedFn
      );
    };
  }, [onRowClicked]);

  const gridInitialized = (flex: any) => {
    initializeGrid && initializeGrid(flex);

    flex.keyActionTab = 'CycleEditable';

    flex.itemsSourceChanged.addHandler((grid: any) => {
      if (!isNil(grid.collectionView)) grid.collectionView.trackChanges = true;
    });

    if (!isNil(flex.collectionView)) flex.collectionView.trackChanges = true;

    gridRef.current = flex;
    setStatus('initialized');
  };

  const getChanges = () => ({
    added: gridRef.current?.collectionView?.itemsAdded.map((i: any) => i),
    edited: gridRef.current?.collectionView?.itemsEdited.map((i: any) => i),
    deleted: gridRef.current?.collectionView?.itemsRemoved.map((i: any) => i),
  });

  /* Update ref object once on loaded grid and once for every columns change */
  React.useImperativeHandle(
    ref,
    () => {
      if (status === 'initialized') {
        const resultRef = gridRef.current;

        resultRef.getChanges = getChanges;
        resultRef.customColumns = columns;
        resultRef.paginationState = paginationState;
        return resultRef;
      }
    },
    [status, columns, paginationState]
  );

  /* Overloaded pagination functions */
  const onPageChange = (
    e: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    const newState = { ...paginationState!, page };
    if (!isNil(paginationOptions?.onPaginationChanged))
      paginationOptions?.onPaginationChanged(newState, e, 'page');
  };

  const onRowsPerPageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newState = {
      ...paginationState,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    };
    if (!isNil(paginationOptions?.onPaginationChanged))
      paginationOptions?.onPaginationChanged(newState, event, 'rowsPerPage');
  };

  return {
    gridInitialized,
    columns,
    rows,
    gridRef,
    paginationState, // export imported paginationState
    paginationOptions: {
      showFirstButton: true,
      showLastButton: true,
      rowsPerPageOptions: [10, 20, 50, 100],
      ...paginationOptions,
      onRowsPerPageChange,
      onPageChange,
    },
    flexGridProps: other,
  };
};

export default useQGridLogic;
